<template>
	<div class="navbar navbar-expand-lg navbar-light">
		<div class="text-center d-lg-none w-100">
			<button type="button" class="navbar-toggler dropdown-toggle">
				<i class="icon-unfold mr-2"></i>
				Footer
			</button>
		</div>

		<div class="navbar-collapse collapse">
			<div class="navbar-text">
				&copy; 2023
				<a href="https://www.merasoft.by" target="_blank">merasoft.by</a>
			</div>
			<div class="version">Версия:
				<span>{{ state.constants.version }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import stateStore from "@/store";

export default {
	setup() {
		const { state } = stateStore;

		return {
			state
		}
	}
}
</script>

<style scoped>
.navbar-collapse.collapse {
	justify-content: space-between;
}

.navbar-text {
	padding: 0.5rem 0;
}
</style>